import { RouteObject } from 'react-router-dom'

export const flowsRoutes: RouteObject[] = [
  {
    path: 'flows',
    children: [
      {
        index: true,
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/flow/flows.page'),
      },
      { path: 'create', lazy: () => import(/* webpackPrefetch: true */ 'app/pages/flow/create/FlowCreationPage') },
      {
        path: ':flowId',
        children: [
          { index: true, lazy: () => import(/* webpackPrefetch: true */ 'app/pages/flow/details/FlowDetail') },
          {
            path: 'edit',
            lazy: () => import(/* webpackPrefetch: true */ 'app/pages/flow/details/overview/FlowEditOverviewPage'),
          },
        ],
      },
    ],
  },
]
