import type { RouteObject } from 'react-router-dom'
import { analyzerRoutes } from '../../analyzer/routes'
import { odinReportsRoutes } from '../odin-reports/odin-reports-routes'
import { flowsRoutes } from '../flow/flows-routes'
import { microservicesRoutes } from '../microservices/microservices-routes'

export const organizationsRoutes: RouteObject[] = [
  {
    path: '/organizations/:organizationId',
    lazy: () => import(/* webpackPrefetch: true */ 'app/routing/KeepOrganizationSelected'),
    children: [
      {
        index: true,
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/organization-details/organization-detail.page'),
      },
      {
        path: 'information',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/organization-details/organization-detail.page'),
      },
      {
        path: 'workspace-groups/:workspaceGroupId',
        lazy: () =>
          import(
            /* webpackPrefetch: true */ 'app/pages/organization-details/workspace-groups/workspace-group-detail.page'
          ).then((m) => ({
            Component: m.WorkspaceGroupDetailPageDetails,
          })),
      },
      {
        path: ':productId',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/report-explorer/report-explorer-organization.page'),
      },
    ],
  },
  {
    path: '/organization/:organizationId',
    lazy: () => import(/* webpackPrefetch: true */ 'app/routing/KeepOrganizationSelected'),
    children: [
      {
        index: true,
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/organization-details/organization-detail.page'),
      },
      {
        path: 'elegible-products',
        lazy: () =>
          import(
            /* webpackPrefetch: true */ 'app/pages/organization-details/elegible-products/OrgElegibleProducts.page'
          ),
      },
      {
        path: 'information',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/organization-details/organization-detail.page'),
      },
      {
        path: 'integrations',
        lazy: () =>
          import(/* webpackPrefetch: true */ 'app/pages/organization-details/integrations/OrgIntegrations.page'),
      },
      {
        path: 'modules',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/organization-details/modules/OrgModules.page'),
      },
      {
        path: 'operations',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/efficiency_operations/efficiency-operations.page'),
      },
      {
        path: 'patch-management',
        children: [
          {
            path: 'dashboard',
            lazy: () =>
              import(/* webpackPrefetch: true */ 'app/pages/patch-management/./summary/PatchManagementSummaryPage'),
          },
          {
            path: 'linux-patch-policies',
            lazy: () =>
              import(
                /* webpackPrefetch: true */ 'app/pages/patch-management/linux-path-policies/LinuxPatchPoliciesPage'
              ),
          },
          {
            path: 'linux-patch-policies/:linuxPatchPolicyId',
            lazy: () =>
              import(
                /* webpackPrefetch: true */ 'app/pages/patch-management/linux-path-policies/LinuxPatchPolicyDetails'
              ),
          },
          {
            path: 'patch-policy-targets',
            lazy: () => import(/* webpackPrefetch: true */ 'app/pages/patch-management/targets/PatchPolicyTargets'),
          },
          {
            path: 'patch-policy-targets/:patchPolicyTargetId',
            lazy: () =>
              import(/* webpackPrefetch: true */ 'app/pages/patch-management/targets/PatchPolicyTargetDetailsPage'),
          },
          {
            path: 'patches',
            lazy: () => import(/* webpackPrefetch: true */ 'app/pages/patch-management/patches/PatchesPage'),
          },
          {
            path: 'reporting-groups',
            lazy: () =>
              import(/* webpackPrefetch: true */ 'app/pages/patch-management/reporting-groups/PMReportingGroupsPage'),
          },
          {
            path: 'security',
            lazy: () => import(/* webpackPrefetch: true */ 'app/pages/patch-management/security/SecurityPage'),
          },
          {
            path: 'summary',
            lazy: () =>
              import(/* webpackPrefetch: true */ 'app/pages/patch-management/./summary/PatchManagementSummaryPage'),
          },
          {
            path: 'targets',
            lazy: () =>
              import(
                /* webpackPrefetch: true */ 'app/pages/patch-management/patch-policy-targets/PatchPolicyTargetsPage'
              ),
          },
          {
            path: 'windows-patch-policies',
            lazy: () =>
              import(
                /* webpackPrefetch: true */ 'app/pages/patch-management/windows-patch-policies/WindowsPatchPoliciesPage'
              ),
          },
          {
            path: 'windows-patch-policies/:windowsPatchPolicyId',
            lazy: () =>
              import(
                /* webpackPrefetch: true */ 'app/pages/patch-management/windows-patch-policies/WindowsPatchPolicyDetailsPage'
              ),
          },
          {
            path: 'workspaces',
            lazy: () => import(/* webpackPrefetch: true */ 'app/pages/patch-management/workspaces/PMWorkspacesPage'),
          },
        ],
      },
      {
        path: 'policies',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/organization-details/policies/OrgPolicies.page'),
      },
      {
        path: 'policy',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/organization-details/policies/PolicyDetailPage'),
      },
      {
        path: 'policy/:policyId',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/organization-details/policies/PolicyDetailPage'),
      },
      {
        path: 'products',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/organization-details/products/OrgProducts.page'),
      },
      {
        path: 'reporting-groups',
        lazy: () =>
          import(/* webpackPrefetch: true */ 'app/pages/organization-details/reporting-groups/OrgReportingGroups.page'),
      },
      {
        path: 'reporting-groups/:reportingGroupId',
        lazy: () =>
          import(
            /* webpackPrefetch: true */ 'app/pages/organization-details/reporting-groups/ReportingGroupsDetailPage'
          ),
      },
      {
        path: 'roles',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/organization-details/roles/OrgRoles.page'),
      },
      {
        path: 'roles/:userRoleId',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/organization-details/roles/RolDetailPage'),
      },
      {
        path: 'tenants',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/organization-details/tenants/OrgTenants.page'),
      },
      {
        path: 'tenants/activations',
        lazy: () =>
          import(/* webpackPrefetch: true */ 'app/pages/organization-details/tenants/activations/ActivationsTenants'),
      },
      {
        path: 'users',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/organization-details/users/OrgUsers.page'),
      },
      {
        path: 'workspace-groups',
        lazy: () =>
          import(/* webpackPrefetch: true */ 'app/pages/organization-details/workspace-groups/OrgWorkspaceGroups.page'),
      },
      {
        path: 'workspace-groups/:workspaceGroupId',
        lazy: () =>
          import(
            /* webpackPrefetch: true */ 'app/pages/organization-details/workspace-groups/workspace-group-detail.page'
          ).then((m) => ({
            Component: m.WorkspaceGroupDetailPageDetails,
          })),
      },
      { path: 'workspaces', lazy: () => import(/* webpackPrefetch: true */ 'app/pages/workspaces/workspaces.page') },
      {
        path: 'workspaces-v2',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/workspaces/workspaces.page.v2'),
      },
      ...microservicesRoutes,
      ...flowsRoutes,
      ...analyzerRoutes,
      ...odinReportsRoutes,
    ],
  },
]
